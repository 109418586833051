/// All colors in standard variable format.
///
/// @group color

$layout_width:  1400px;
$content_width:  780px;
$sidebar_width:  300px;


$colors: (
    // section--darks
	black: #000000,
	nero: #151515,
	charcoal: #232323,
	ashes: #333333,

	// Mids
	dusty: #999999,
	boulder: #7a7a7a,
	silver: #c0c0c0,
	alto: #eeeeee,

	// Lights
	white: #FFFFFF,
	whitesmoke: #f4f4f4,
	ghost: #f9f9f9,

	// Rose
	rose: #D5AA9A,
	rosegold: #DDBAB1,
	duststorm: #E5CCC9,
	bizzare: #EEDEDA,

	// Gold
	dough: #C4B392,
	sands: #D9CA9F,

	// Dust
	celeste: #D1D2CA,
	westar: #DCD9D2,
	feta: #E8EBE0,
	desertstorm: #F4F2EE,

	//Special

	bebe: #009999,
	peach: #ff9999,

	// Reds

	cardinal: #C92228,
	milano: #C21A01,
	fire: #cf4e1b,

	// Kickstarter

	kickstarter: #2BDE73,
	messanger: #0084ff,
);



// Box Shadows


.box-shadow-1dp {
	@include boxShadow(1);
}

.box-shadow-4dp {
	@include boxShadow(4);
}

.box-shadow-6dp {
	@include boxShadow(6);
}
.box-shadow-8dp {
	@include boxShadow(8);
}

.box-shadow-12dp {
	@include boxShadow(12);
}

.box-shadow-16dp {
	@include boxShadow(16);
}
.box-shadow-24dp {
	@include boxShadow(24);
}

// Text Shadows


.text-shadow-1dp {
	@include boxShadow(1);
}

.text-shadow-4dp {
	@include boxShadow(4);
}

.text-shadow-6dp {
	@include boxShadow(6);
}
.text-shadow-8dp {
	@include boxShadow(8);
}

.text-shadow-12dp {
	@include boxShadow(12);
}

.text-shadow-16dp {
	@include boxShadow(16);
}
.text-shadow-24dp {
	@include boxShadow(24);
}

