
#mstr-theme {

	.mstr-sponsors-wrapper {
		.mstr-sponsors-wrapper-inner {
			text-align: center;

			img {
				display: inline-block;
				width: 100px;
				height: auto;
				margin: 0 5px;
				opacity: 0.35;
			}
		}
	}


	.mstr-profile-wrapper {
		max-width: 720px;
		margin: 0 auto;
		padding: 40px 20px;
		border-top: 1px solid color(whitesmoke);
		border-bottom: 1px solid color(whitesmoke);

		.mstr-profile-wrapper-inner {

			.mstr-profile--img {
				display: inline-block;
				width: 200px;
				height: 200px;
				overflow: hidden;
				margin: 0 auto 20px;
				border-radius: 100%;
				border: 6px solid color(white);
				@include boxShadow(16);

				img {
					width: 100%;
					height: auto;
				}
			}
		}


		h2 {
			margin-top: 0;
		}

		h3 {
			font-family: 'Playfair Display';
		}


	}

	.mstr-group-wrapper {
		// max-width: 640px;
		margin: 0 auto;
		padding: 40px 20px;
		border-top: 1px solid color(whitesmoke);
		border-bottom: 1px solid color(whitesmoke);

		.mstr-group-wrapper-inner {
			text-align: center;


			.mstr-group--img {
				position: relative;
				overflow: hidden;
				display: block;
				width: 200px;
				height: 200px;
				vertical-align: middle;
				margin: -40px auto;
				border-radius: 100%;
				z-index: 100;
				border: 6px solid color(white);
				@include boxShadow(16);

				// 1st & 5th

				&:first-child,
				&:nth-child(2n),
				&:nth-child(5n) {
					vertical-align: middle;
					display: inline-block;
					width: 170px;
					height: 170px;
					z-index: 0;
					margin: 0 -20px;
					border: none;
					@include boxShadow(4);
				}

				&:first-child {
					margin-top: 0;
					box-shadow: none;
				}
				&:nth-child(5n) {
					margin-bottom: 0;
					box-shadow: none;
				}


				@include susy-breakpoint($bp-md, $susy) {
					display: inline-block;
					margin: 0 -20px;
					

					// 1st & 5th

					&:first-child,
					&:nth-child(5n) {
						width: 145px;
						height: 145px;
						z-index: 0;
						@include boxShadow(1);
					}

					&:first-child {
						margin-top: 0;
					}
					&:nth-child(5n) {
						margin-bottom: 0;
					}

					// 2nd & 4th
					&:nth-child(2n) {
						width: 170px;
						height: 170px;
						z-index: 20;
						@include boxShadow(6);
					}

				}


				img {
					width: 100%;
					height: auto;
					transition: 0.3s ease;
				}

				a {
					display: block;
					width: 100%;
					height: 100%;
					color: color(white);
				}

				label {
					opacity: 0;
					position: absolute;
					top: 50%;
					z-index: 100;
					width: 100%;
					margin: -10px 0 0 0;
					padding: 5px 10px;
					text-align: center;
					border-radius: 3px;

				}

				&::after {
					opacity: 0;
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(0,0,0,0.45);
					transition: 0.3s ease;
				}


				&:hover {
					z-index: 200;

					img {
						cursor: pointer;
						transform: scale(1.1);
					}
					label {
						opacity: 1;
					}
					&::after {
						opacity: 1;
					}
				}
			}
		}


		h2 {
			margin-top: 0;
		}

		h3 {
			font-family: 'Playfair Display';
		}


	}

	.mstr-package-wrapper {
		display: inline-block;
		max-width: $layout_width;
		margin: 0 auto;
		padding: 0 20px;
		text-align: center;
	  
		.mstr-package {
			width: 100%;
			overflow: hidden;
			display: inline-block;
			margin: 10px 0;
			border-radius: 5px;
			background: color(white);
			box-shadow: 0px 4px 8px rgba(0,0,0,0.15);
			transition: 0.2s ease;
			vertical-align: top;

			@include susy-breakpoint($bp-md, $susy) {
				width: 44%;
				margin: 10px;

				&:hover {
				// transform: scale(1.01);
				}
			}
	    
			@include susy-breakpoint($bp-lg, $susy) {
				width: 22%;
			}
		    
			&.free {

				.mstr-package--price {
					color: #ccc;
				}
			}
		    
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		    
			h3 {
				font-size: 1.5em;
				padding: 20px;
				margin: 0;
				text-align: center;
				color: color(white);
				background: color(charcoal);

				span {
					display: block;
					font-size: 0.5em;
					font-weight: normal;
					line-height: 1.5em;
					opacity: 0.6;
					text-transform: uppercase;
				}

				&.feature {
					background: color(bebe);
					// background: color(fire);
				}

				&.personal {
					background: color(peach);
				}
			}
		    
			.mstr-package--content {
				padding: 20px;

				@include susy-breakpoint($bp-md, $susy) {
					min-height: 270px;
				}

				ul {
					padding: 0;
					margin: 0;
					text-align: left;

					li {
						font-size: 0.8em;
						list-style: none;
						padding: 10px;
						border-bottom: 1px solid color(whitesmoke);

						&:last-child {
							border-bottom: none;
						}
					}
				}
			}

			.btn, .button {
				width: 100%;
				margin-bottom: 0;
			}

			.mstr-package--price {
				padding: 20px;
				text-align: center;
				font-size: 1.3em;
				font-weight: bold;
				font-family: 'Roboto Condensed', sans-serif;
				color: color(charcoal);
				background: color(whitesmoke);

				span {
					font-weight: normal;
					font-size: .7em;
				}
			}
		   
		    
		}
	}


	.mstr-benefits-wrapper {
		max-width: $content-width;
		margin: auto;
		vertical-align: top;
		text-align: left;

		.mstr-option {
			padding: 20px;
			vertical-align: top;
			border: 1px solid color(alto);
			border-radius: 5px;

			@include susy-breakpoint($bp-md, $susy) {
				display: inline-block;
				width: span(6 of 12);
			}

			h3 {
				text-align: left;
				margin-bottom: 0;
			}

			ul {
				padding: 20px;
				background: color(whitesmoke);
				border-radius: 5px;

				li {
					margin: 10px 0 10px 20px;
					list-style: disc;
					font-size: 14px;
				}
			}
		}
	}
	

}