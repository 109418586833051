#mstr-theme {

	// Logo

	.mstr-logo-wrapper {

		.mstr-logo-wrapper-inner {
			
			h1 {
				font-family: 'Playfair Display';
				font-size: 2.8em;
				line-height: 1.2;
				text-align: center;
				font-weight: bold;
				letter-spacing: -2px;
				color: color(fire);

				span {
					display: block;
					// opacity: 0.5;
					font-family: 'Raleway';
					font-size: 0.23em;
					letter-spacing: 2px;
					text-transform: uppercase;
				}
			}
		}

		
	}


}