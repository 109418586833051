#mstr-theme {


	.mstr-banner-wrapper {
		position: relative;
		background-color: color(charcoal);
		text-align: center;
		margin: 0 0 40px 0;

		.mstr-banner-wrapper-inner {
			position: relative;
			padding: 0 0 40px 0;

			@include susy-breakpoint($bp-md, $susy) {
				min-height: 600px;
				padding: 0;
			}
		}


		&:hover {
			.mstr-social-wrapper,
			.mstr-breadcrumbs-wrapper {
				opacity: 1;
				transition: 0.3s ease;
			}
		}

		&.banner--extended {
			.mstr-banner-wrapper-inner {

				@include susy-breakpoint($bp-md, $susy) {
					min-height: 100vh;
				}
			}
		}

		&.banner--short {
			.mstr-banner-wrapper-inner {
				min-height: 250px;
			}
		}


		// Light + Dark Text

		&.banner--dark {

			.banner__source {
				&::after {
					background: -moz-linear-gradient(top, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
				}
			}

			&.content--default,
			&.content--center {
				.banner__source {
					&::before {
						content: '';
						background: rgba(0,0,0,0.25);
					}
				}
			}

			&.content--left {
				.banner__source {
					&::before {
						content: '';
						background: -moz-linear-gradient(left, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0) 75%); /* FF3.6-15 */
						background: -webkit-linear-gradient(left, rgba(0,0,0,0.45) 0%,rgba(0,0,0,0) 75%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to right, rgba(0,0,0,0.45) 0%,rgba(0,0,0,0) 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#00FFFFFF',GradientType=1 ); /* IE6-9 */
					}
				}
			}

			&.content--right {
				.banner__source {
					&::before {
						content: '';
						background: -moz-linear-gradient(left, rgba(0,0,0,0) 25%, rgba(0,0,0,0.25) 100%); /* FF3.6-15 */
						background: -webkit-linear-gradient(left, rgba(0,0,0,0) 25%,rgba(0,0,0,0.25) 100%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to right, rgba(0,0,0,0) 25%,rgba(0,0,0,0.25) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00FFFFFF', endColorstr='#FFFFFF',GradientType=1 ); /* IE6-9 */
					}
				}
			}

			&.banner--no-overlay {
				.banner__source {
					&::before {
						background: none;
					}
				}			
			}
		}

		&.banner--light {

			.mstr-logo-wrapper {
				color: color(charcoal);
			}

			.banner__caption {
				h1, h2, p, em, strong {
					color: color(charcoal);
				}

				h1 {
					color: color(charcoal);

					&::before,
					&::after {
						background: color(charcoal);
					}

					span {
						color: inherit;
					}

					.banner__ghost-text {
						color: color(charcoal);
					}
				}

				i.me-signature {
					color: color(charcoal);
				}
			}

			.mstr-social-wrapper.social--icon,
			.mstr-social-wrapper.social--list {
				ul {
					li {
						a, i {
							color: color(charcoal);
						}
					}
				}
			}

			.mstr-breadcrumbs-wrapper {
				ul {
					li {
						color: rgba(0,0,0,0.35);

						a, i {
							color: color(charcoal);
						}

						&::after {
							color: rgba(0,0,0,0.35);
						}

						strong {
							color: rgba(0,0,0,0.35);
						}
					}
				}
			}

			.banner__scroll-arrow {
				color: color(charcoal);
			}

			.banner__source {
				&::after {
					background: -moz-linear-gradient(top, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top, rgba(155,155,155,0.15) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom, rgba(255,255,255,0.25) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
				}
			}

			&.content--default,
			&.content--center {
				.banner__source {
					&::before {
						content: '';
						background: rgba(255,255,255,0.1);
					}
				}
			}

			&.content--left {
				.banner__source {
					&::before {
						content: '';
						background: -moz-linear-gradient(left, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 75%); /* FF3.6-15 */
						background: -webkit-linear-gradient(left, rgba(255,255,255,0.1) 0%,rgba(255,255,255,0) 75%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to right, rgba(255,255,255,0.1) 0%,rgba(255,255,255,0) 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#00FFFFFF',GradientType=1 ); /* IE6-9 */
					}
				}
			}

			&.content--right {
				.banner__source {
					&::before {
						content: '';
						background: -moz-linear-gradient(left, rgba(255,255,255,0) 25%, rgba(255,255,255,0.1) 100%); /* FF3.6-15 */
						background: -webkit-linear-gradient(left, rgba(255,255,255,0) 25%,rgba(255,255,255,0.1) 100%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to right, rgba(255,255,255,0) 25%,rgba(255,255,255,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00FFFFFF', endColorstr='#FFFFFF',GradientType=1 ); /* IE6-9 */
					}
				}
			}

			&.banner--no-overlay {
				.banner__source {
					&::before {
						background: none;
					}
				}			
			}

		}

		&.content--left {
			
			@include susy-breakpoint($bp-md, $susy) {
					text-align: left;

					.banner__caption {
						max-width: $layout_width;

						h1 {
							padding-left: 0;

							&::before {
								display: none;
							}

							.banner__ghost-text {
								@include center(false, true);
								left: 0;
								margin-left: 0;
							}
						}

						h2 {
							padding-left: 0;
							margin-left: 0;
						}

						p {
							margin-left: 0;
							max-width: 480px;
						}
					}
			}

			.banner__caption {
				h1 {

				}
			}
		
		}

		&.content--right {

			@include susy-breakpoint($bp-md, $susy) {
					text-align: right;

					.banner__caption {
						max-width: $layout_width;

						h1 {
							padding-right: 0;

							&::after {
								display: none;
							}

							.banner__ghost-text {
								@include center(false, true);
								right: 0;
								left: auto;
								margin-left: 0;
							}
						}

						h2 {
							padding-right: 0;
							margin-right: 0;
						}

						p {
							margin-right: 0;
							max-width: 480px;
						}
					}
			}

			.banner__caption {
				
				h1 {

				}
			}

		}

		&.background--cover {
			.banner__source {
				background-size: cover;
				background-attachment: fixed;

				img {
					position: absolute;
					@include objectFit;
				}
			}
		}	

		&.background--parallax {
			background-color: transparent;
			background-image: none;
			
			.banner__source {
			    min-height: 400px;
			    background-size: cover;
				background-attachment: scroll;
			    background-image: none;
			    background-color: transparent;

			    @include susy-breakpoint($bp-md, $susy) {
					background-attachment: fixed;
				}

			    img {
					display: none;
				}
			}
		}


		&.background--color {
			.banner__source {
				background-image: none;

				img {
					display: none;
				}
			}
		}

		.banner__scroll-arrow {
			position: absolute;
			bottom: 20px;
			left: 50%;
			margin-left: -20px;
			z-index: 1;
			width: 40px;
			height: 40px;
			color: color(white);
			opacity: 1;
			animation: bounce 1s 5s infinite;
			transition: opacity 0.3s ease;

			i {
				font-size: 40px;
			}

			&.hide {
				opacity: 0;
			}

			&:hover {
				cursor: pointer;
			}
		}

		.mstr-logo-wrapper {
			color: color(white);
		}

		.banner__source {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
			overflow: hidden;
			pointer-events: none;
			background-size: cover;
			background-attachment: initial;
			background-position: center center;
			background-repeat: no-repeat;
			background-color: color(charcoal);
			// background-image: url('../img/mestre-empire-default-banner.jpg');

			@include susy-breakpoint($bp-md, $susy) {
		
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				background: rgba(0,0,0,0.25);
			}

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 120px;
				z-index: 1;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				// background: rgba(255,255,255,0.05);
			}

		}

		.banner__caption {
			position: relative;
			z-index: 1; 
			opacity: 1;
			width: 100%;
			padding: 80px 0 0 0;

			@include susy-breakpoint($bp-md, $susy) {
				@include center(true,true);
				max-width: 980px;
			}

			.banner__caption-inner {
				margin: auto;
				padding: 20px;

			}

			&.hide {
				opacity: 0;
				transition: 1s 0.2s ease;
			}
			
			h1 {
				color: color(white);

				&::before,
				&::after {
					background: color(white);
				}

				span {
					color: inherit;
					opacity: .75;
				}

				.banner__ghost-text {
					@include center(true, true);
					z-index: -1;
					color: color(white);
					opacity: 0.08;
					font-family: 'Playfair Display';
					font-size: 200px;
					line-height: 1;
					text-align: center;
					letter-spacing: -0.1em;
					margin-top: -0.15em;
					text-transform: capitalize;
					pointer-events: none;

					i {
						font-size: 160px;
						color: inherit;
					}
				}
			}

			h2 {
				color: color(white);
				margin: 20px auto 0 auto;
				padding: 0 20px 0 20px;

				&::after {
					display: none;
				}
			}

			p {
				margin: 1em auto;
				font-size: 1.5em;
				line-height: 1.5;
				color: color(white);
			}

			blockquote,
			blockquote p {
				font-size: 1.2em;
			}

			blockquote {

				p {
					margin: 0;
				}

				&::before,
				&::after {
					opacity: 0.15;
				}
			}

			em {
				color: color(white);
			}


			strong {
				color: color(white);
			}

			i.me-signature {
				font-size: 50px;
				color: color(white);
			}

		}

		.btn {
			color: color(white);
			background: color(ashes);
			@include boxShadow(12);
			font-size: 16px;
			padding: 5px 45px;

			&.btn--icon {
				padding-right: 70px;

				i {
					top: 5px;
					right: 30px;
				}
			}

			&:hover {
				color: color(white);
				background: color(charcoal);
				text-decoration: none;
				transform: scale(0.99);
				@include boxShadow(1);
			}
		}

		.mstr-social-wrapper.social--icon {
			position: absolute;
			bottom: 20px;
			right: 20px;
			z-index: 1;

			ul {
				li {
					a, i {
						color: color(white);
					}
				}
			}
		}

		.mstr-social-wrapper.social--list {
			position: absolute;
			top: 35%;
			left: 0px;
			z-index: 10;
			transform: rotate(90deg);
			transform-origin: bottom left;
			
			ul {
				li {
					
					a, i {
						color: color(white);
					}
				}
			}
		}

		.mstr-breadcrumbs-wrapper {
			position: absolute;
			bottom: 20px;
			left: 20px;
			z-index: 10;
		}

		// Animation

		.mstr-social-wrapper,
		.mstr-breadcrumbs-wrapper {
			opacity: 0;
			transition: 2s ease;
			margin: 0;
		}

	}

}