#mstr-theme {

	&.active-nav {
		height: 100%;
		overflow: hidden;
	}

	&.admin-bar {
		margin-top: 0px !important;

		.sticky {
			margin-top: 0px;

			@include susy-breakpoint($bp-md, $susy) {
				margin-top: 30px;
			}
		}
	}


	.mstr-main-nav-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999999;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		font-weight: 500;
		background: color(white);
		transform: translate(-100%, 0);
		transition: transform 0.3s cubic-bezier(.8,0,0,1);

		@include susy-breakpoint($bp-md, $susy) {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: 100;
			width: 100%;
			height: auto;
			opacity: 1;
			transition: top 0.3s ease;
			transform: translate(0, 0);
			background: none;
			box-shadow: none;
		}

		.mstr-main-nav-wrapper-inner {
			position: relative;
			min-height: 100vh;
			height: 100%;
			margin: auto;
			padding: 60px 0 0;
			text-align: left;
			overflow: scroll;

			@include susy-breakpoint($bp-md, $susy) {
				position: relative;
				min-height: 60px;
				margin: 15px auto;
				padding: 0;
				text-align: center;
				overflow: inherit;
			}
		}

		&.active {
			transform: translate(0, 0);

			.main-nav__menu {
				opacity: 1;
				pointer-event: auto;

				@include susy-breakpoint($bp-md, $susy) {
					display: inline-block;
					opacity: 1;
					pointer-events: auto;
				}
				
				.main-nav__menu--left {
					animation: slideInDown 0.5s ease;
				}
				.main-nav__menu--right {
					animation: slideInDown 0.5s ease;
				}
			}
		}

		// Light Banner

		&.banner--light {
			ul {
				> li {
					> a {
						@include susy-breakpoint($bp-md, $susy) {
							color: color(charcoal);
						}

						.cart__quantity {
							color: color(charcoal);
						}
					}

					&:hover {
						> a {
							color: color(dough);

							@include susy-breakpoint($bp-md, $susy) {
								color: rgba(0,0,0,0.35);
							}
						}
					}
					
					&.current_page_item,
					&.current_page_parent {
						> a {
							color: color(dough);

							@include susy-breakpoint($bp-md, $susy) {
								// color: rgba(0,0,0,0.35);
								color: color(charcoal);
								background: color(white);
							}
						}
					}

					&.main-nav__trigger {

						&.active {
							> a {
								color: color(charcoal);
								@include susy-breakpoint($bp-md, $susy) {
									
								}
							}

							&:hover {
								color: color(dough);

								a {
									color: rgba(0,0,0,0.35);
								}
							}
						}

						> a {

							&::before,
							&::after {
								background: color(charcoal);
							}

						}

					}
				}
			}

			.main-nav__logo {
				> a {

					@include susy-breakpoint($bp-md, $susy) {
						color: color(charcoal);
					}
				}
			}

			.woo-multi-currency {

				.wmc-currency-wrapper {					
					.wmc-current-currency {
						color: color(charcoal);
					}
				}
			}
		}

		&.hide {
			opacity: 0;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			line-height: 0;
			border-bottom: 1px solid color(whitesmoke);

			@include susy-breakpoint($bp-md, $susy) {
				border: none;
			}

			> li {
				position: relative;
				display: block;
				margin: 0;
				border-bottom: 1px solid color(whitesmoke);
				@include container();
				vertical-align: top;
				text-align: center;

				&.last {
					border-bottom: none;
				}


				@include susy-breakpoint($bp-md, $susy) {
					display: inline-block;
					border-bottom: none;
					margin: 0 10px;
					text-align: left;
				}

				> a {
					position: relative;
					display: block;
					line-height: 60px;
					padding: 0;
					margin: 0;
					color: color(charcoal);
					text-transform: uppercase;
					font-size: 14px;
					font-family: "Roboto Condensed";
					border-radius: 3px;
					transition: 0.3s ease;
					cursor: pointer;
					vertical-align: top;

					@include susy-breakpoint($bp-md, $susy) {
						display: inline-block;
						color: color(white);
						margin: 15px 0;
					    padding: 0 12px;
						line-height: 30px;

					}

					i {
						color: inherit;
					}

					.cart__quantity {
						display: none;
						font-size: 11px;
						line-height: 1;
						vertical-align: super;
						transition: 0s ease;
						color: color(white);

						@include susy-breakpoint($bp-md, $susy) {
							display: inline-block;
						}
					}

					.cart__subtotal {
						position: static;
						top: auto;
						left: auto;
						display: block;
						max-width: 180px;
						margin: 0 auto;
						padding: 10px 15px;
						background: color(nero);
						border-radius: 3px;
						font-size: 12px;
						line-height: 14px;
						color: color(white);
						text-transform: uppercase;

						@include susy-breakpoint($bp-md, $susy) {
							display: inline-block;
							margin: 0 5px 0 0;
							padding: 5px 15px;
						}

					}

				}

				&:hover {
					cursor: pointer;

					> a {
						text-decoration: none;
						color: color(dough);

						@include susy-breakpoint($bp-md, $susy) {
							color: rgba(255,255,255,0.35);
						}

						.cart__subtotal {
							
						}
					}

					.sub-menu { display: block; }

				}

				&.main-nav__cart {
					padding: 20px 0 0 0;

					@include susy-breakpoint($bp-md, $susy) {
						padding: 0;
					}
					
					i.material-icons {
						padding: 0;
						border-radius: none;
						box-shadow: none;
						background: none;
					}

					.cart__label {
						display: inline-block;

						@include susy-breakpoint($bp-md, $susy) {
							display: none;
						}
					}

					.sub-menu {
						li { 
							padding: 0;
						}
					}
						
					&:hover {
						.sub-menu {
							// display: none;
						}
					}	

					&.active {
						.sub-menu {
							// display: block;
						}
					}

				}

				&.current_page_item,
				&.current_page_parent {

					> a {
						color: color(dough);

						@include susy-breakpoint($bp-md, $susy) {
							// color: rgba(255,255,255,0.35);
							color: color(white);
							background: color(charcoal);
						}
					}
					
				}

				&.main-nav__trigger {
					position: fixed;
					top: 0;
					right: -55px;
					z-index: 100;
					background: color(white);
					padding: 10px;
					border-radius: 0 0 3px 0;
					border: none;
					@include boxShadow(4);

					@include susy-breakpoint($bp-md, $susy) {
						position: relative;
						top: auto;
						right: auto;
						padding: 0;
						background: none;
						border-radius: 0;
						box-shadow: none;

					}

					&.active {
						right: 0;
						box-shadow: none;

						@include susy-breakpoint($bp-md, $susy) {
							right: auto;
						}

						> a {
							color: color(charcoal);

							@include susy-breakpoint($bp-md, $susy) {
								color: color(white);
							}

							&::before {
								top: 14px;
								left: 10px;
								width: 15px;
								transform: rotate(-45deg);
							}
							&::after {
								top: 14px;
								left: 10px;
								width: 15px;
								transform: rotate(45deg);
							}
						}
						&:hover {
							> a {
								color: color(dough);

								@include susy-breakpoint($bp-md, $susy) {
									color: rgba(255,255,255,0.35);
								}
							}
						}
					}

					&:hover {
						a {
							// color: color(dough);
							&::before,
							&::after {
								// background: color(dough);
							}
						}
					}

					> a {
						position: relative;
						padding-left: 35px;
						line-height: 30px;
					    width: 0;
					    overflow: hidden;

					    i {
					    	color: inherit;
					    }

					    @include susy-breakpoint($bp-md, $susy) {
					    	width: auto;
					    	overflow: auto;
					    }

						&::before,
						&::after {
							content: '';
							position: absolute;
							height: 2px;
							background: color(charcoal);
							transition: 0.3s ease;

							@include susy-breakpoint($bp-md, $susy) {
								background: color(white);
							}
						}

						&::before {
							top: 10px;
							left: 0px;
							width: 25px;
						}
						&::after {
							top: 17px;
							left: 10px;
							width: 15px;
						}

					}
				}

				.sub-menu {
					display: none;
					padding: 10px;
					background: rgba(0,0,0,0.03);

					@include susy-breakpoint($bp-md, $susy) {
						position: absolute;
						left: 50%;
						top: 60px;
						z-index: 300;
						background: color(white);
						min-width: 260px;
						text-align: left;
						padding: 10px;
						margin: 0px;
						transform: translate(-50%, 0);
						@include boxShadow(1);

						&::before {
							content: '';
							position: absolute;
							z-index: 1;
							height: 0;
							width: 0;
							border: 10px solid transparent;
							border-bottom-color: color(alto);
							@include center(true, false);
							top: -19px;
						}

						&::after {
							content: '';
							position: absolute;
							z-index: 2;
							height: 0;
							width: 0;
							border: 10px solid transparent;
							border-bottom-color: color(white);
							@include center(true, false);
							top: -18px;
						}

						p {
							margin: 1.5em 0;
							font-size: 12px;
							text-align: center;
							text-transform: uppercase;
							color: color(charcoal);
						}

					}

					> li {
						position: relative;
						display: block;
						margin: 0;
						padding-left: 20px;
						border-bottom: 1px solid color(whitesmoke);

						@include susy-breakpoint($bp-md, $susy) {
							border-bottom: none;
							padding-left: 0;
						}

						&.last {
							border-bottom: none;
						}

						&.current-menu-item {
							a {
								color: color(dough);
							}
						}

						> a {
							display: block;
							line-height: 35px;
							font-family: "Raleway";
							font-size: 14px;
							margin: 0;
							padding: 0 10px;
							color: color(charcoal);
							text-transform: capitalize;

							&:hover {
								color: color(dough);
							}
						}



						.btn,
						.button {
							height: 50px;
							padding: 0px 30px;
							margin: 10px 0 0 0;
							font-size: 14px;
							line-height: 50px;
							text-align: center;
							text-transform: uppercase;
							color: color(charcoal);
							background: color(whitesmoke);

							@include susy-breakpoint($bp-md, $susy) {
								display: block;
								height: 30px;
								line-height: 30px;

							}

							&:hover {
								color: color(charcoal);
								background: color(ghost);
								cursor: pointer;
								text-decoration: none;
							}

							i {
								margin-right: 5px;
							}


							// Alternative

							&.btn--alt,
							&.button-alt {
								color: color(white);
								background: color(charcoal);

								&:hover {
									color: color(white);
									background: color(ashes);
								}
							}
						}

					}

					.cart__sub-menutotal {
						margin-top: 10px;
						padding-top: 10px;
						border-top: 1px solid color(whitesmoke);
						color: color(charcoal);
						text-transform: uppercase;
						text-align: center;

						label {
							font-size: 12px;
							color: color(charcoal);
							display: block;
						}

						.product__price {
							display: inline-block;
							color: color(charcoal);
						}
					}

					// Product

					.product {
						margin: 0 0 5px 0;
						padding: 5px;
						opacity: 1;
						transition: opacity 0.3s ease;
						line-height: 0;
						background: transparent;

						&:hover {
							.product__info {
								// opacity: 0.5;
							}
						}                          


						.product__source {
							position: relative;
							display: inline-block;
							width: 50px;
							height: 50px;

							&:hover {
								&::before {
									opacity: 1;
								}
							}

							&::before {
								content: '\f00d';
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								font-family: "FontAwesome";
								font-size: 16px;
								text-align: center;
								line-height: 50px;
								color: color(fire);
								background: rgba(255,255,255,0.9);
								opacity: 0;
								transition: 0.3s ease;
								pointer-events: none;
							}

							img {
								object-fit: cover;
								display: block;
								width: 100%;
								height: 100%;
								line-height: 0;
							}

							> a {
								display: block;
								width: 100%;
								height: 100%;
							}
						}


						> a {
							display: block;
							width: 100%;
							height: 100%;
							padding: 0;
							text-decoration: none;
						}

						
						.product__info {
							display: inline-block;
							margin-left: 10px;
							vertical-align: top;

							h5 {
								font-size: 14px;
								margin: 0px;
								color: color(charcoal);
								letter-spacing: 0;

								span {
									display: block;
									text-transform: capitalize;
									font-size: 12px;
									line-height: 1.2;
									font-family: 'Raleway', sans-serif;
									color: color(boulder);
								}

								a {
									text-transform: uppercase;
									line-height: 1.5;
								}

								&:after {
									display: none;
								}
							}

							.product__price {
								font-size: 18px;
								color: color(white);
							}

						}
					}

					.btn {
						display: block;
					}
				}
			}
		}

		.main-nav__menu {
			display: block;
			opacity: 1;
			pointer-events: auto;
			transition: 0.3s ease;

			@include susy-breakpoint($bp-md, $susy) {
				display: inline-block;
				opacity: 0;
				pointer-events: none;
			}

			ul.main-nav__menu--left,
			ul.main-nav__menu--right {

				
			}

			ul.main-nav__menu--left {

				@include susy-breakpoint($bp-md, $susy) {
					position: absolute;
					top: 0;
					right: 50%;
					margin-right: 30px;
					text-align: right;
				}
			}
			ul.main-nav__menu--right {

				@include susy-breakpoint($bp-md, $susy) {
					position: absolute;
					top: 0;
					left: 50%;
					margin-left: 30px;
					text-align: left;
				}
			}

		}


		.main-nav__logo {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 10;
			width: 100%;
			line-height: 60px;
			font-size: 32px;
			text-align: center;
			background: color(white);

		    @include susy-breakpoint($bp-md, $susy) {
		    	position: absolute;
				top: 0;
				width: auto;
				margin: 0;
			    @include center(true, false);
			    background: none;
		    }

			> a {
				display: block;
				vertical-align: middle;
				color: color(charcoal);
				text-decoration: none;

				@include susy-breakpoint($bp-md, $susy) {
					color: color(white);
				}

				i {
					color: inherit;
					font-size: inherit;
				}

				&:hover {
					animation: rubberBand 0.5s;
				}
				
			}

		}

		ul.main-nav__controls--left,
		ul.main-nav__controls--right {

			@include susy-breakpoint($bp-md, $susy) {
				position: absolute;
				top: 0;
				z-index: 20;
			}

			> li {
				display: block;

				@include susy-breakpoint($bp-md, $susy) {
					display: inline-block;
					margin: 0;
				}
			}
		}

		ul.main-nav__controls--left {

			> li {
				&.main-nav__search {

					a.search__trigger {
						display: none;

						@include susy-breakpoint($bp-md, $susy) {
							display: inline-block;
						}
					}

					form {
						input {
							margin: 0;
						}
					}

				}
			}

			@include susy-breakpoint($bp-md, $susy) {
				left: 20px;
			}
		}

		ul.main-nav__controls--right {

			@include susy-breakpoint($bp-md, $susy) {
				right: 20px;
			}

			> li {

				@include susy-breakpoint($bp-md, $susy) {
					display: inline-block;
				}

				.sub-menu {
					left: auto;
					right: -10px;
					transform: translate(0, 0);

					&::before {
						@include center(false, false);
						left: auto;
						right: 20px;
					}
					&::after {
						@include center(false, false);
						left: auto;
						right: 20px;
					}
				}

				&:hover {
					i.favorite_border {
						animation: pulse 0.5s infinite;
					}
				}

				&.last {
					.sub-menu {
						right: -10px;
						min-width: 200px;

						&::before,
						&::after {
							right: 10px;
						}
					}
				}
			}
		}


		// sticky

		&.sticky {

			@include susy-breakpoint($bp-md, $susy) {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				background: color(white);
				background: rgba(255,255,255,0.97);
				padding-top: 0;
				height: 60px;
				border-bottom: 1px solid color(whitesmoke);
				animation: mainNav 0.5s ease-out;
				@include boxShadow(16);
				box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.05), 
							0px 16px 24px 2px rgba(0,0,0,0.02), 
							0px 6px 30px 5px rgba(0,0,0,0.02);
			}

			.woo-multi-currency {

				.wmc-currency-wrapper {					
					.wmc-current-currency {
						color: color(charcoal);
					}
				}
			}


			.mstr-main-nav-wrapper-inner {
				margin: 0 auto;
			}

			&::before {
				display: none;
			}


			ul {
				> li {
					> a {
						color: color(charcoal);

						@include susy-breakpoint($bp-md, $susy) {
							
						}


						.cart__quantity {
							color: color(charcoal);
						}
					}

					&:hover {
						> a {
							color: color(dough);

							@include susy-breakpoint($bp-md, $susy) {
								color: rgba(0,0,0,0.35);
							}
						}
					}

					&.current_page_item,
					&.current_page_parent {
						> a {
							color: color(dough);

							@include susy-breakpoint($bp-md, $susy) {
								// color: rgba(0,0,0,0.35);
								color: color(white);
								background: color(charcoal);
							}
						}
					}


					&.main-nav__trigger {

						&.active {
							> a {
								color: color(charcoal);

								&::before,
								&::after {
									background: color(charcoal);
								}

							}
							&:hover {
								> a {
									color: rgba(0,0,0,0.35);
								}
							}
						}

						&:hover {
							> a {
								&::before,
								&::after {
									color: rgba(0,0,0,0.35);
								}

								span {
									color: color(charcoal);
								}
							}
						}

						> a {

							&::before,
							&::after {
								background: color(charcoal);
							}

						}
					}
				}
			}

			.sub-menu {
				> ul {
					> li {
						> a {
							color: color(white);
						}

						&:hover {
							> a {
								color: color(dough);
							}
						}
					}
				}
			}


			.main-nav__menu {
				transition: 0.3s ease;
			}

			.main-nav__logo {

				> a {
					color: color(charcoal);

				}
			}
		}


	}

	// Menu List 


	ul.menu-list {
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: left;

		li {
			position: relative;
			border-bottom: 1px solid color(whitesmoke);
			font-size: 14px;
			line-height: 40px;
			@include container();

			a {
				display: block;
				text-decoration: none;
			}

			span {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 14px;
				font-family: 'Roboto Condensed', sans-serif;
			}

			p {
				display: block;
				font-size: 12px;
				line-height: 1.5;
				text-align: right;
				margin-top: 40px;
				@include span(9 of 12 last);


			}

			ul.sub-menu {
				li {
					border-top: 1px solid color(whitesmoke);
					padding-left: 20px;

					&.last {
						border-bottom: none;
					}
				}
			}
		}
	}


	// Menu Inline

	ul.menu-inline {
		margin: 0 auto;
		padding: 0;
		list-style: none;
		min-width: 100%;
		text-align: center;

		li {
			position: relative;
			display: inline-block;
			border-right: 1px solid color(whitesmoke);
			font-size: 14px;
			font-family: "Roboto Condensed";
			text-transform: uppercase;
			line-height: 40px;
			@include container();

			&.last {
				border-right: none;
			}

			a {
				display: block;
				text-decoration: none;
				padding: 0 5px;
			}

			span {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 14px;
				font-family: 'Roboto Condensed', sans-serif;
			}

			p {
				display: block;
				font-size: 12px;
				line-height: 1.5;
				text-align: right;
				margin-top: 40px;
				@include span(9 of 12 last);


			}
		}
	}
	
	
	.woo-multi-currency {
		display: block;
		position: relative;
		text-align: center;

		@include susy-breakpoint($bp-md, $susy) {
			display: inline-block;
		}

		.wmc-currency-wrapper {
			.wmc-current-currency {
				display: inline-block;
				position: relative;
				width: 110px;
				margin: 15px 0;
				padding: 0;
				line-height: 30px;
				background: none;
				border: none;
				color: color(charcoal);

				@include susy-breakpoint($bp-md, $susy) {
					color: color(white);
				}

				&::after {
					content: '\f0d7';
					font-family: 'FontAwesome';
				}

			}

			.wmc-sub-currency {
				position: relative;
				top: auto;
				padding: 10px;
				background: rgba(0,0,0,0.03);

				@include susy-breakpoint($bp-md, $susy) {
					position: absolute;
					top: 60px;
					background: color(white);
					text-align: left;
					padding: 10px;
					margin: 0px;
					@include boxShadow(1);

					&::before {
						content: '';
						position: absolute;
						z-index: 1;
						height: 0;
						width: 0;
						border: 10px solid transparent;
						border-bottom-color: color(alto);
						@include center(true, false);
						top: -19px;
					}
					&::after {
						content: '';
						position: absolute;
						z-index: 2;
						height: 0;
						width: 0;
						border: 10px solid transparent;
						border-bottom-color: color(white);
						@include center(true, false);
						top: -18px;
					}

					p {
						margin: 1.5em 0;
						font-size: 12px;
						text-align: center;
						text-transform: uppercase;
						color: color(charcoal);
					}

				}

				.wmc-currency {
					position: relative;
					display: block;
					margin: 0;
					padding: 0;
					border-bottom: 1px solid color(whitesmoke);

					@include susy-breakpoint($bp-md, $susy) {
						border-bottom: none;
						padding-left: 0;
					}

					&.last {
						border-bottom: none;
					}

					> a {
						display: block;
						line-height: 35px;
						font-family: "Raleway";
						font-size: 14px;
						margin: 0;
						padding: 0 10px;
						color: color(charcoal);
						text-transform: capitalize;

						&:hover {
							color: color(dough);
						}
					}

					img {
						width: 20px;
						height: auto;
						margin-right: 5px;
					}

				}
			}

			img {
				width: 20px;
				height: auto;
				margin-right: 5px;
			}
		}
	}



	@keyframes mainNav {
		0% {
			top: -120px;
		}
		100% {
			top: 0px;
		}
	}
	@-webkit-keyframes mainNav {
		0% {
			top: -120px;
		}
		100% {
			top: 0px;
		}
	}

}