/**
 * @file
 * Theme functions
 */

/// Strip units from a number.
/// @group Utility
/// @access public
/// @param {int} $value - Value that you want to strip units from
///
/// @example scss
///   strip-units( 16px )
///
@function strip-units( $value ) {
    @return $value / ( $value * 0 + 1 );
}

/// Interact with the color map
/// @group Colors
/// @access public
/// @param {string} $key - Name of the color
///
/// @example scss
///   .block {
///     color: color(boulder);
///   }
///
@function color( $key ) {
    @if map-has-key( $colors, $key ) {
        @return map-get( $colors, $key );
    } @else {
        @warn 'Error: There is no color #{$key} associated';
    }
}

/// Interact with the spacing unit
/// @group Utility
/// @access public
/// @param {int} $mult [1] - Spacing unit multiplier
///
/// @example scss
///   .block {
///     padding-left: su();
///   }
///
///   .block-2 {
///     padding-left: su(2); // multiplies spacing unit by two
///   }
///
@function spacing-unit( $mult: 1 ) {
    @if unitless( $mult ) {
        @return $mult * $base-spacing-unit;
    } @else {
        @warn 'Error: must pass a unitless value, was #{$mult}';
    }
}

/// Spacing unit shortcut
/// @group Utility
/// @access public
/// @param {int} $mult [1] - Spacing unit multiplier
///
/// @example scss
///   su( 16px )
@function su( $mult: 1 ) {
    @return spacing-unit( $mult );
}
