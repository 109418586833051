* {
	padding: 0;
	margin: 0;
	list-style: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


#mstr-theme {


	h1, h2, h3, h4, h5 {
		position: relative;
		display: block;
		margin: 40px auto 40px auto;
		padding: 0;
		font-family: 'Roboto Condensed', sans-serif;
		line-height: 1.2em;
		color: color(charcoal);
		transition: 0.3s ease;
		max-width: $content_width;
		text-align: center;

		span {
			display: block;
			opacity: 0.75;
			font-family: 'Raleway';
			font-size: 0.5em;
			text-transform: uppercase;
		}

	}

	sup {
		// font-size: 0.75em;
		font-weight: normal;
		top: -0.2em;
		// vertical-align: top;
		// margin: 0 2px 0 0;
	}



	p {
		max-width: $content_width;
		margin: 2em 20px;
		// font-size: 1.3em;
		line-height: 1.7;

		@include susy-breakpoint($bp-md, $susy) {
			margin: 2em auto;
		}

		&.intro-text {
			font-size: 1.5em;
		}

		&.mstr-page--print {
			font-size: 1em;
			line-height: 1.5em;
			text-align: center;
			opacity: 0.5;
		}

		&.feature-text {
			font-family: 'Playfair Display';
			font-size: 60px;
			line-height: 1;
			color: color(sands);
			margin: 0 auto;
		}

		&.caption-text {
			display: block;
		    padding: 2px 10px;
			font-size: 14px;
		    line-height: 1.5;
		    background: #f4f4f4;
		    border-radius: 3px;
		}

		&.small-text {
			display: block;
			font-size: 1em;
		    line-height: 1.5;
		    margin: 0.8em auto;
		}

		&.hashtag-text {
			font-size: 1.5em;
			font-weight: bold;
			font-family: "Roboto Condensed";
			text-transform: uppercase;
			opacity: 0.25;
		}
	}

	label {
		display: block;
		font-size: 12px;
		text-transform: uppercase;
		margin: 0 auto;
		// font-weight: bold;
		// color: color(charcoal);
	}

	// Links

	a {
		color: color(fire);
		text-decoration: none;

		&:active,
		&.active {
			font-weight: bold;
		}

		&:hover {
			cursor: pointer;
			color: color(sands);
		}

		&.post-edit-link {
			display: inline-block;
			line-height: 1.8;
			font-size: 14px;
			padding: 0 15px;
			margin: 0 5px 0 0;
			background: color(charcoal);
			color: color(white);
			border-radius: 3px;

			&:hover {
				background: color(nero);
			}
		}
	}

	strong, b {
		color: color(charcoal);
	}

	em {
		font-family: 'Playfair Display';
		font-style: italic;
		color: color(charcoal);
	}


	blockquote {
		position: relative;
		display: table;
		padding: 40px;
		margin: 2em auto;
		font-family: 'Playfair Display';
		font-style: italic;
		font-size: 1.3em;
		color: color(charcoal);
		max-width: $content_width;

		p {
			margin: 0;
			font-size: 1em;
		}

		&::before,
		&::after {
			position: absolute;
			display: block;
			width: 50px;
			height: 50px;
			text-align: center;
			font-family: 'FontAwesome';
			font-size: 30px;
			color: rgba(0,0,0,0.05);
		}

		&::before {
			content: '\f10d';
			top: 20px;
			left: -10px;
		}

		&::after {
			content: '\f10e';
			position: absolute;
			bottom: 20px;
			right: -10px;

		}

		span {
			display: block;
			position: absolute;
			left: 40px;
			bottom: 0;
		    font-family: 'Roboto Condensed';
		    font-size: 0.8em;
		    text-transform: uppercase;
		    color: color(dough);
		}
	}

	hr {
		border: none;
		border-bottom: 1px solid rgba(0,0,0,0.05);
		margin: 60px 0;
	}


	ul.list {
		margin: 0 auto;
		padding-left: 25px;
		max-width: $content_width;

		li {
			position: relative;
			margin: 0;
			text-align: left;
			font-size: 1.2em;

			&::before {
				content: '';
				@include center(false, true);
				left: -25px;
				width: 8px;
				height: 1px;
				background: color(charcoal);
			}

			p, blockquote {
				font-size: inherit;
			}
		}
	}

	pre {
		max-width: $content_width;
		margin: 2em auto;
	}

}