@charset "UTF-8";
.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
	&.infinite {
		animation-iteration-count: infinite;
	}
	&.hinge {
		animation-duration: 2s;
	}
	&.flipOutX {
		animation-duration: .75s;
	}
	&.flipOutY {
		animation-duration: .75s;
	}
	&.bounceIn {
		animation-duration: .75s;
	}
	&.bounceOut {
		animation-duration: .75s;
	}
	&.flip {
		//Instead of the line below you could use @include backface-visibility($visibility)
		backface-visibility: visible;
		animation-name: flip;
	}
}
@-webkit-keyframes bounce {
	from {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0,0,0);
	}
	20% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0,0,0);
	}
	53% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0,0,0);
	}
	80% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0,0,0);
	}
	to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0,0,0);
	}
	40% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -30px, 0);
	}
	43% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -30px, 0);
	}
	70% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -15px, 0);
	}
	90% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0,-4px,0);
	}
}
@keyframes bounce {
	from {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0,0,0);
	}
	20% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0,0,0);
	}
	53% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0,0,0);
	}
	80% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0,0,0);
	}
	to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0,0,0);
	}
	40% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -30px, 0);
	}
	43% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -30px, 0);
	}
	70% {
		animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -15px, 0);
	}
	90% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0,-4px,0);
	}
}
.bounce {
	animation-name: bounce;
	//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
	transform-origin: center bottom;
}
@-webkit-keyframes flash {
	from {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	to {
		opacity: 1;
	}
	25% {
		opacity: 0;
	}
	75% {
		opacity: 0;
	}
}
@keyframes flash {
	from {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	to {
		opacity: 1;
	}
	25% {
		opacity: 0;
	}
	75% {
		opacity: 0;
	}
}
.flash {
	animation-name: flash;
}
@-webkit-keyframes pulse {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.05, 1.05, 1.05);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
}
@keyframes pulse {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.05, 1.05, 1.05);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
}
.pulse {
	animation-name: pulse;
}
@-webkit-keyframes rubberBand {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.95, 1.05, 1);
	}
	75% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.05, .95, 1);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
}
@keyframes rubberBand {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.95, 1.05, 1);
	}
	75% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.05, .95, 1);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
}
.rubberBand {
	animation-name: rubberBand;
}
@-webkit-keyframes shake {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
	10% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-10px, 0, 0);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-10px, 0, 0);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-10px, 0, 0);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-10px, 0, 0);
	}
	90% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-10px, 0, 0);
	}
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(10px, 0, 0);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(10px, 0, 0);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(10px, 0, 0);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(10px, 0, 0);
	}
}
@keyframes shake {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
	10% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-10px, 0, 0);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-10px, 0, 0);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-10px, 0, 0);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-10px, 0, 0);
	}
	90% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-10px, 0, 0);
	}
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(10px, 0, 0);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(10px, 0, 0);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(10px, 0, 0);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(10px, 0, 0);
	}
}
.shake {
	animation-name: shake;
}
@-webkit-keyframes headShake {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	0.5% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-6px) rotateY(-9deg);
	}
	1.5% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(5px) rotateY(7deg);
	}
	2.5% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-3px) rotateY(-5deg);
	}
	3.5% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2px) rotateY(3deg);
	}
}
@keyframes headShake {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(0);
	}
	0.5% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-6px) rotateY(-9deg);
	}
	1.5% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(5px) rotateY(7deg);
	}
	2.5% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(-3px) rotateY(-5deg);
	}
	3.5% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateX(2px) rotateY(3deg);
	}
}
.headShake {
	animation-timing-function: ease-in-out;
	animation-name: headShake;
}
@-webkit-keyframes swing {
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 15deg);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -10deg);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 5deg);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -5deg);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 0deg);
	}
}
@keyframes swing {
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 15deg);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -10deg);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 5deg);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -5deg);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 0deg);
	}
}
.swing {
	//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
	transform-origin: top center;
	animation-name: swing;
}
@-webkit-keyframes tada {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
	10% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
	}
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}
	90% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
}
@keyframes tada {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
	10% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
	}
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}
	70% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}
	90% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
}
.tada {
	animation-name: tada;
}
@-webkit-keyframes wobble {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	15% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
	}
	45% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
	}
	75% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
@keyframes wobble {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	15% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
	}
	45% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
	}
	75% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
.wobble {
	animation-name: wobble;
}
@-webkit-keyframes jello {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	0.1% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	1.2% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-12.5deg) skewY(-12.5deg);
	}
	2.3% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(6.25deg) skewY(6.25deg);
	}
	3.4% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-3.125deg) skewY(-3.125deg);
	}
	4.5% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(1.5625deg) skewY(1.5625deg);
	}
	5.6% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-0.78125deg) skewY(-0.78125deg);
	}
	6.7% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(0.390625deg) skewY(0.390625deg);
	}
	7.8% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
	}
}
@keyframes jello {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	0.1% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	1.2% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-12.5deg) skewY(-12.5deg);
	}
	2.3% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(6.25deg) skewY(6.25deg);
	}
	3.4% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-3.125deg) skewY(-3.125deg);
	}
	4.5% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(1.5625deg) skewY(1.5625deg);
	}
	5.6% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-0.78125deg) skewY(-0.78125deg);
	}
	6.7% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(0.390625deg) skewY(0.390625deg);
	}
	7.8% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
	}
}
.jello {
	animation-name: jello;
	//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
	transform-origin: center;
}
@-webkit-keyframes bounceIn {
	from {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}
	20% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1);
	}
	40% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.9, .9, .9);
	}
	60% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.03, 1.03, 1.03);
	}
	80% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.97, .97, .97);
	}
	to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.3, .3, .3);
	}
}
@keyframes bounceIn {
	from {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}
	20% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1);
	}
	40% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.9, .9, .9);
	}
	60% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.03, 1.03, 1.03);
	}
	80% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.97, .97, .97);
	}
	to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1, 1, 1);
	}
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.3, .3, .3);
	}
}
.bounceIn {
	animation-name: bounceIn;
}
@-webkit-keyframes bounceInDown {
	from {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}
	60% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 25px, 0);
	}
	75% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -10px, 0);
	}
	90% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 5px, 0);
	}
	to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -3000px, 0);
	}
}
@keyframes bounceInDown {
	from {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}
	60% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 25px, 0);
	}
	75% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -10px, 0);
	}
	90% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 5px, 0);
	}
	to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -3000px, 0);
	}
}
.bounceInDown {
	animation-name: bounceInDown;
}
@-webkit-keyframes bounceInLeft {
	from {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}
	60% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(25px, 0, 0);
	}
	75% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-10px, 0, 0);
	}
	90% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(5px, 0, 0);
	}
	to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-3000px, 0, 0);
	}
}
@keyframes bounceInLeft {
	from {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}
	60% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(25px, 0, 0);
	}
	75% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-10px, 0, 0);
	}
	90% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(5px, 0, 0);
	}
	to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
	0% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-3000px, 0, 0);
	}
}
.bounceInLeft {
	animation-name: bounceInLeft;
}
@-webkit-keyframes bounceInRight {
	from {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(3000px, 0, 0);
	}
	60% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-25px, 0, 0);
	}
	75% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(10px, 0, 0);
	}
	90% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-5px, 0, 0);
	}
	to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
@keyframes bounceInRight {
	from {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(3000px, 0, 0);
	}
	60% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-25px, 0, 0);
	}
	75% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(10px, 0, 0);
	}
	90% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-5px, 0, 0);
	}
	to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
.bounceInRight {
	animation-name: bounceInRight;
}
@-webkit-keyframes bounceInUp {
	from {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 3000px, 0);
	}
	60% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -20px, 0);
	}
	75% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 10px, 0);
	}
	90% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -5px, 0);
	}
	to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
}
@keyframes bounceInUp {
	from {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 3000px, 0);
	}
	60% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -20px, 0);
	}
	75% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 10px, 0);
	}
	90% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -5px, 0);
	}
	to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
}
.bounceInUp {
	animation-name: bounceInUp;
}
@-webkit-keyframes bounceOut {
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.9, .9, .9);
	}
	50% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1);
	}
	55% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.3, .3, .3);
	}
}
@keyframes bounceOut {
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.9, .9, .9);
	}
	50% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1);
	}
	55% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(1.1, 1.1, 1.1);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.3, .3, .3);
	}
}
.bounceOut {
	animation-name: bounceOut;
}
@-webkit-keyframes bounceOutDown {
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 10px, 0);
	}
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -20px, 0);
	}
	45% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -20px, 0);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 2000px, 0);
	}
}
@keyframes bounceOutDown {
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 10px, 0);
	}
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -20px, 0);
	}
	45% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -20px, 0);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 2000px, 0);
	}
}
.bounceOutDown {
	animation-name: bounceOutDown;
}
@-webkit-keyframes bounceOutLeft {
	20% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(20px, 0, 0);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-2000px, 0, 0);
	}
}
@keyframes bounceOutLeft {
	20% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(20px, 0, 0);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-2000px, 0, 0);
	}
}
.bounceOutLeft {
	animation-name: bounceOutLeft;
}
@-webkit-keyframes bounceOutRight {
	20% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-20px, 0, 0);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(2000px, 0, 0);
	}
}
@keyframes bounceOutRight {
	20% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-20px, 0, 0);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(2000px, 0, 0);
	}
}
.bounceOutRight {
	animation-name: bounceOutRight;
}
@-webkit-keyframes bounceOutUp {
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -10px, 0);
	}
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 20px, 0);
	}
	45% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 20px, 0);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -2000px, 0);
	}
}
@keyframes bounceOutUp {
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -10px, 0);
	}
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 20px, 0);
	}
	45% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 20px, 0);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -2000px, 0);
	}
}
.bounceOutUp {
	animation-name: bounceOutUp;
}
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.fadeIn {
	animation-name: fadeIn;
}
@-webkit-keyframes fadeInDown {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
@keyframes fadeInDown {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
.fadeInDown {
	animation-name: fadeInDown;
}
@-webkit-keyframes fadeInDownBig {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -2000px, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
@keyframes fadeInDownBig {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -2000px, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
.fadeInDownBig {
	animation-name: fadeInDownBig;
}
@-webkit-keyframes fadeInLeft {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-100%, 0, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
@keyframes fadeInLeft {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-100%, 0, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
.fadeInLeft {
	animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInLeftBig {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-2000px, 0, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
@keyframes fadeInLeftBig {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-2000px, 0, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
.fadeInLeftBig {
	animation-name: fadeInLeftBig;
}
@-webkit-keyframes fadeInRight {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
@keyframes fadeInRight {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
.fadeInRight {
	animation-name: fadeInRight;
}
@-webkit-keyframes fadeInRightBig {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(2000px, 0, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
@keyframes fadeInRightBig {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(2000px, 0, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
.fadeInRightBig {
	animation-name: fadeInRightBig;
}
@-webkit-keyframes fadeInUp {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 100%, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
@keyframes fadeInUp {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 100%, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
.fadeInUp {
	animation-name: fadeInUp;
}
@-webkit-keyframes fadeInUpBig {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 2000px, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
@keyframes fadeInUpBig {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 2000px, 0);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
.fadeInUpBig {
	animation-name: fadeInUpBig;
}
@-webkit-keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
.fadeOut {
	animation-name: fadeOut;
}
@-webkit-keyframes fadeOutDown {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 100%, 0);
	}
}
@keyframes fadeOutDown {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 100%, 0);
	}
}
.fadeOutDown {
	animation-name: fadeOutDown;
}
@-webkit-keyframes fadeOutDownBig {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 2000px, 0);
	}
}
@keyframes fadeOutDownBig {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 2000px, 0);
	}
}
.fadeOutDownBig {
	animation-name: fadeOutDownBig;
}
@-webkit-keyframes fadeOutLeft {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-100%, 0, 0);
	}
}
@keyframes fadeOutLeft {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-100%, 0, 0);
	}
}
.fadeOutLeft {
	animation-name: fadeOutLeft;
}
@-webkit-keyframes fadeOutLeftBig {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-2000px, 0, 0);
	}
}
@keyframes fadeOutLeftBig {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-2000px, 0, 0);
	}
}
.fadeOutLeftBig {
	animation-name: fadeOutLeftBig;
}
@-webkit-keyframes fadeOutRight {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0);
	}
}
@keyframes fadeOutRight {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0);
	}
}
.fadeOutRight {
	animation-name: fadeOutRight;
}
@-webkit-keyframes fadeOutRightBig {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(2000px, 0, 0);
	}
}
@keyframes fadeOutRightBig {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(2000px, 0, 0);
	}
}
.fadeOutRightBig {
	animation-name: fadeOutRightBig;
}
@-webkit-keyframes fadeOutUp {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -100%, 0);
	}
}
@keyframes fadeOutUp {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -100%, 0);
	}
}
.fadeOutUp {
	animation-name: fadeOutUp;
}
@-webkit-keyframes fadeOutUpBig {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -2000px, 0);
	}
}
@keyframes fadeOutUpBig {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -2000px, 0);
	}
}
.fadeOutUpBig {
	animation-name: fadeOutUpBig;
}
@-webkit-keyframes flip {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
		animation-timing-function: ease-out;
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
		animation-timing-function: ease-out;
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
		animation-timing-function: ease-in;
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) scale3d(.95, .95, .95);
		animation-timing-function: ease-in;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px);
		animation-timing-function: ease-in;
	}
}
@keyframes flip {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
		animation-timing-function: ease-out;
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
		animation-timing-function: ease-out;
	}
	50% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
		animation-timing-function: ease-in;
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) scale3d(.95, .95, .95);
		animation-timing-function: ease-in;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px);
		animation-timing-function: ease-in;
	}
}
@-webkit-keyframes flipInX {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		animation-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		animation-timing-function: ease-in;
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		opacity: 1;
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px);
	}
}
@keyframes flipInX {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		animation-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		animation-timing-function: ease-in;
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		opacity: 1;
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px);
	}
}
.flipInX {
	//Instead of the line below you could use @include backface-visibility($visibility)
	backface-visibility: visible !important;
	animation-name: flipInX;
}
@-webkit-keyframes flipInY {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		animation-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
		animation-timing-function: ease-in;
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
		opacity: 1;
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px);
	}
}
@keyframes flipInY {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		animation-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
		animation-timing-function: ease-in;
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
		opacity: 1;
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px);
	}
}
.flipInY {
	//Instead of the line below you could use @include backface-visibility($visibility)
	backface-visibility: visible !important;
	animation-name: flipInY;
}
@-webkit-keyframes flipOutX {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		opacity: 0;
	}
}
@keyframes flipOutX {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		opacity: 0;
	}
}
.flipOutX {
	animation-name: flipOutX;
	//Instead of the line below you could use @include backface-visibility($visibility)
	backface-visibility: visible !important;
}
@-webkit-keyframes flipOutY {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		opacity: 0;
	}
}
@keyframes flipOutY {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px);
	}
	30% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		opacity: 0;
	}
}
.flipOutY {
	//Instead of the line below you could use @include backface-visibility($visibility)
	backface-visibility: visible !important;
	animation-name: flipOutY;
}
@-webkit-keyframes lightSpeedIn {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0) skewX(-30deg);
		opacity: 0;
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(20deg);
		opacity: 1;
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-5deg);
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
		opacity: 1;
	}
}
@keyframes lightSpeedIn {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0) skewX(-30deg);
		opacity: 0;
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(20deg);
		opacity: 1;
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: skewX(-5deg);
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
		opacity: 1;
	}
}
.lightSpeedIn {
	animation-name: lightSpeedIn;
	animation-timing-function: ease-out;
}
@-webkit-keyframes lightSpeedOut {
	from {
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0) skewX(30deg);
		opacity: 0;
	}
}
@keyframes lightSpeedOut {
	from {
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0) skewX(30deg);
		opacity: 0;
	}
}
.lightSpeedOut {
	animation-name: lightSpeedOut;
	animation-timing-function: ease-in;
}
@-webkit-keyframes rotateIn {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -200deg);
		opacity: 0;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
		opacity: 1;
	}
}
@keyframes rotateIn {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -200deg);
		opacity: 0;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
		opacity: 1;
	}
}
.rotateIn {
	animation-name: rotateIn;
}
@-webkit-keyframes rotateInDownLeft {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
		opacity: 1;
	}
}
@keyframes rotateInDownLeft {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
		opacity: 1;
	}
}
.rotateInDownLeft {
	animation-name: rotateInDownLeft;
}
@-webkit-keyframes rotateInDownRight {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
		opacity: 1;
	}
}
@keyframes rotateInDownRight {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
		opacity: 1;
	}
}
.rotateInDownRight {
	animation-name: rotateInDownRight;
}
@-webkit-keyframes rotateInUpLeft {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
		opacity: 1;
	}
}
@keyframes rotateInUpLeft {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
		opacity: 1;
	}
}
.rotateInUpLeft {
	animation-name: rotateInUpLeft;
}
@-webkit-keyframes rotateInUpRight {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -90deg);
		opacity: 0;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
		opacity: 1;
	}
}
@keyframes rotateInUpRight {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -90deg);
		opacity: 0;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
		opacity: 1;
	}
}
.rotateInUpRight {
	animation-name: rotateInUpRight;
}
@-webkit-keyframes rotateOut {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center;
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 200deg);
		opacity: 0;
	}
}
@keyframes rotateOut {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center;
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 200deg);
		opacity: 0;
	}
}
.rotateOut {
	animation-name: rotateOut;
}
@-webkit-keyframes rotateOutDownLeft {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}
}
@keyframes rotateOutDownLeft {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0;
	}
}
.rotateOutDownLeft {
	animation-name: rotateOutDownLeft;
}
@-webkit-keyframes rotateOutDownRight {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
}
@keyframes rotateOutDownRight {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
}
.rotateOutDownRight {
	animation-name: rotateOutDownRight;
}
@-webkit-keyframes rotateOutUpLeft {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
}
@keyframes rotateOutUpLeft {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0;
	}
}
.rotateOutUpLeft {
	animation-name: rotateOutUpLeft;
}
@-webkit-keyframes rotateOutUpRight {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 90deg);
		opacity: 0;
	}
}
@keyframes rotateOutUpRight {
	from {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right bottom;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 90deg);
		opacity: 0;
	}
}
.rotateOutUpRight {
	animation-name: rotateOutUpRight;
}
@-webkit-keyframes hinge {
	0% {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
		animation-timing-function: ease-in-out;
	}
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 80deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
		animation-timing-function: ease-in-out;
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 80deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
		animation-timing-function: ease-in-out;
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 60deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
		animation-timing-function: ease-in-out;
		opacity: 1;
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 60deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
		animation-timing-function: ease-in-out;
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 700px, 0);
		opacity: 0;
	}
}
@keyframes hinge {
	0% {
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
		animation-timing-function: ease-in-out;
	}
	20% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 80deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
		animation-timing-function: ease-in-out;
	}
	60% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 80deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
		animation-timing-function: ease-in-out;
	}
	40% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 60deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
		animation-timing-function: ease-in-out;
		opacity: 1;
	}
	80% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate3d(0, 0, 1, 60deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: top left;
		animation-timing-function: ease-in-out;
		opacity: 1;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 700px, 0);
		opacity: 0;
	}
}
.hinge {
	animation-name: hinge;
}
@-webkit-keyframes rollIn {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
@keyframes rollIn {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
	}
	to {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: none;
	}
}
.rollIn {
	animation-name: rollIn;
}
@-webkit-keyframes rollOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
	}
}
@keyframes rollOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
	}
}
.rollOut {
	animation-name: rollOut;
}
@-webkit-keyframes zoomIn {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.3, .3, .3);
	}
	50% {
		opacity: 1;
	}
}
@keyframes zoomIn {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.3, .3, .3);
	}
	50% {
		opacity: 1;
	}
}
.zoomIn {
	animation-name: zoomIn;
}
@-webkit-keyframes zoomInDown {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}
@keyframes zoomInDown {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}
.zoomInDown {
	animation-name: zoomInDown;
}
@-webkit-keyframes zoomInLeft {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}
@keyframes zoomInLeft {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}
.zoomInLeft {
	animation-name: zoomInLeft;
}
@-webkit-keyframes zoomInRight {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}
@keyframes zoomInRight {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}
.zoomInRight {
	animation-name: zoomInRight;
}
@-webkit-keyframes zoomInUp {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}
@keyframes zoomInUp {
	from {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	60% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}
.zoomInUp {
	animation-name: zoomInUp;
}
@-webkit-keyframes zoomOut {
	from {
		opacity: 1;
	}
	50% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.3, .3, .3);
	}
	to {
		opacity: 0;
	}
}
@keyframes zoomOut {
	from {
		opacity: 1;
	}
	50% {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.3, .3, .3);
	}
	to {
		opacity: 0;
	}
}
.zoomOut {
	animation-name: zoomOut;
}
@-webkit-keyframes zoomOutDown {
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center bottom;
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}
@keyframes zoomOutDown {
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.1, .1, .1) translate3d(0, 2000px, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center bottom;
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}
.zoomOutDown {
	animation-name: zoomOutDown;
}
@-webkit-keyframes zoomOutLeft {
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(.1) translate3d(-2000px, 0, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left center;
	}
}
@keyframes zoomOutLeft {
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(42px, 0, 0);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(.1) translate3d(-2000px, 0, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left center;
	}
}
.zoomOutLeft {
	animation-name: zoomOutLeft;
}
@-webkit-keyframes zoomOutRight {
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(.1) translate3d(2000px, 0, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right center;
	}
}
@keyframes zoomOutRight {
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale(.1) translate3d(2000px, 0, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: right center;
	}
}
.zoomOutRight {
	animation-name: zoomOutRight;
}
@-webkit-keyframes zoomOutUp {
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center bottom;
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}
@keyframes zoomOutUp {
	40% {
		opacity: 1;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
		animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
	}
	to {
		opacity: 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: scale3d(.1, .1, .1) translate3d(0, -2000px, 0);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: center bottom;
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
	}
}
.zoomOutUp {
	animation-name: zoomOutUp;
}
@-webkit-keyframes slideInDown {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
}
@keyframes slideInDown {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
}
.slideInDown {
	animation-name: slideInDown;
}
@-webkit-keyframes slideInLeft {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
}
@keyframes slideInLeft {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
}
.slideInLeft {
	animation-name: slideInLeft;
}
@-webkit-keyframes slideInRight {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
}
@keyframes slideInRight {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
}
.slideInRight {
	animation-name: slideInRight;
}
@-webkit-keyframes slideInUp {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
}
@keyframes slideInUp {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}
	to {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
}
.slideInUp {
	animation-name: slideInUp;
}
@-webkit-keyframes slideOutDown {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 100%, 0);
	}
}
@keyframes slideOutDown {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 100%, 0);
	}
}
.slideOutDown {
	animation-name: slideOutDown;
}
@-webkit-keyframes slideOutLeft {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-100%, 0, 0);
	}
}
@keyframes slideOutLeft {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(-100%, 0, 0);
	}
}
.slideOutLeft {
	animation-name: slideOutLeft;
}
@-webkit-keyframes slideOutRight {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0);
	}
}
@keyframes slideOutRight {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(100%, 0, 0);
	}
}
.slideOutRight {
	animation-name: slideOutRight;
}
@-webkit-keyframes slideOutUp {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -100%, 0);
	}
}
@keyframes slideOutUp {
	from {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, 0, 0);
	}
	to {
		visibility: hidden;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translate3d(0, -100%, 0);
	}
}
.slideOutUp {
	animation-name: slideOutUp;
}